import { Box, Button, Container, TextField, Typography } from "@mui/material";

const Contact = () => {
    return (
        <form
            name="contact"
            method="POST"
            data-netlify="true"
            netlify-honeypot="baku-field"
        >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="redirect" value="/thank-you" />

            <Container maxWidth="sm" sx={{ mt: 4 }}>
                <p style={{ display: "none" }}>
                    <label>
                        Don’t fill this out if you’re human: <input name="baku-field" />
                    </label>
                </p>

                <Box
                    sx={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        padding: "20px",
                    }}
                >
                    <Typography variant="h5" align="center" gutterBottom>
                        Contact Us
                    </Typography>

                    <TextField
                        fullWidth
                        margin="normal"
                        id="name"
                        name="name"
                        label="Your Name"
                        variant="outlined"
                        required
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        id="email"
                        name="email"
                        label="Your Email"
                        type="email"
                        variant="outlined"
                        required
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        id="message"
                        name="message"
                        label="Message"
                        multiline
                        rows={4}
                        variant="outlined"
                        required
                    />

                    <Box sx={{ textAlign: "center", mt: 2 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            type="submit"
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Container>
        </form>
    );
};

export default Contact;
