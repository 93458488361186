import { Box, Grid, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Grid container p={2} textAlign="left" gap={3}>
      <Box width="100%">
        <Typography fontWeight={700}>Privacy Policy</Typography>
        <Typography>
          This privacy policy applies to the Tawheedi app (hereby referred to as
          "Application") for mobile devices that was created by Tawheedi (hereby
          referred to as "Service Provider") as a Free service. This service is
          intended for use "AS IS".
        </Typography>
      </Box>

      <Box width="100%">
        <Typography fontWeight={700}>
          What information does the Application obtain and how is it used?
        </Typography>
        <Typography>
          The Application does not obtain any information when you download and
          use it. Registration is not required to use the Application.
        </Typography>
      </Box>

      <Box width="100%">
        <Typography fontWeight={700}>
          Does the Application collect precise real time location information of
          the device?
        </Typography>
        <Typography>
          This Application does not collect precise information about the
          location of your mobile device.
        </Typography>
      </Box>

      <Box width="100%">
        <Typography fontWeight={700}>
          Do third parties see and/or have access to information obtained by the
          Application?
        </Typography>
        <Typography>
          Since the Application does not collect any information, no data is
          shared with third parties.
        </Typography>
      </Box>

      <Box width="100%">
        <Typography fontWeight={700}>What are my opt-out rights?</Typography>
        <Typography>
          You can stop all collection of information by the Application easily
          by uninstalling it. You may use the standard uninstall processes as
          may be available as part of your mobile device or via the mobile
          application marketplace or network.
        </Typography>
      </Box>
      <Box width="100%">
        <Typography fontWeight={700}>Children</Typography>
        <Typography>
          The Application is not used to knowingly solicit data from or market
          to children under the age of 13.
        </Typography>
      </Box>

      <Box width="100%">
        <Typography>
          The Service Provider does not knowingly collect personally
          identifiable information from children. The Service Provider
          encourages all children to never submit any personally identifiable
          information through the Application and/or Services. The Service
          Provider encourage parents and legal guardians to monitor their
          children's Internet usage and to help enforce this Policy by
          instructing their children never to provide personally identifiable
          information through the Application and/or Services without their
          permission. If you have reason to believe that a child has provided
          personally identifiable information to the Service Provider through
          the Application and/or Services, please contact the Service Provider
          (tawheedi.kw@gmail.com) so that they will be able to take the
          necessary actions. You must also be at least 16 years of age to
          consent to the processing of your personally identifiable information
          in your country (in some countries we may allow your parent or
          guardian to do so on your behalf).
        </Typography>
      </Box>

      <Box width="100%">
        <Typography fontWeight={700}>Security</Typography>
        <Typography>
          The Service Provider is concerned about safeguarding the
          confidentiality of your information. However, since the Application
          does not collect any information, there is no risk of your data being
          accessed by unauthorized individuals.
        </Typography>
      </Box>

      <Box width="100%">
        <Typography fontWeight={700}>Changes</Typography>
        <Typography>
          This Privacy Policy may be updated from time to time for any reason.
          The Service Provider will notify you of any changes to their Privacy
          Policy by updating this page with the new Privacy Policy. You are
          advised to consult this Privacy Policy regularly for any changes, as
          continued use is deemed approval of all changes.
        </Typography>
      </Box>

      <Box width="100%">
        <Typography>
          This privacy policy is effective as of 2024-04-05
        </Typography>
      </Box>

      <Box width="100%">
        <Typography fontWeight={700}>Your Consent</Typography>
        <Typography>
          By using the Application, you are consenting to the processing of your
          information as set forth in this Privacy Policy now and as amended by
          the Service Provider.
        </Typography>
      </Box>

      <Box width="100%">
        <Typography fontWeight={700}>Contact Us</Typography>
        <Typography>
          If you have any questions regarding privacy while using the
          Application, or have questions about the practices, please contact the
          Service Provider via email at tawheedi.kw@gmail.com.
        </Typography>
      </Box>
    </Grid>
  );
};

export default PrivacyPolicy;
