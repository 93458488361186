import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";

import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ThankYou from "./pages/ThankYou";

const theme = createTheme({
    palette: {
        primary: {
            main: "#F1EEDB",
        },
        secondary: {
            main: "#4F8CAD",
        },
    },
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Navbar />

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path="/thank-you" element={<ThankYou />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
};

export default App;
