import { Container, Typography } from "@mui/material";

const ThankYou = () => {
    return (
        <Container maxWidth="sm" sx={{ mt: 4, textAlign: "center" }}>
            <Typography variant="h4" gutterBottom>
                Thank You!
            </Typography>
            <Typography variant="body1">
                Your message has been sent. We will get back to you shortly.
            </Typography>
        </Container>
    );
};

export default ThankYou;
