import { Grid } from "@mui/material";

const Home = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="95vh"
      width="100%"
    >
      <img
        style={{ height: "100%", width: "100%", objectFit: "fill" }}
        src="/assets/tawhadi_bg.png"
        alt="logo"
      />
    </Grid>
  );
};

export default Home;
